import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog14 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Peter Weiss: Hölderlin" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Peter Weiss: Hölderlin",
                link: "/k/14",
              },
            ]}
          />
          <PageTitle>Peter Weiss: Hölderlin</PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer src="katalog/14-peter-weiss" />
              <Small color="whisper">
                Aus: Peter Weiss, Hölderlin. Stück in zwei Akten © Suhrkamp
                Verlag Frankfurt am Main 1971.
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog14
